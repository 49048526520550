body {
  background-color: #000;
}

.menu {
  right: 0px;
  position: fixed;
  background: #ffffff;
  font-weight: bold;
  height: 100%; 
  overflow: hidden;
  max-width: 50px;
  z-index: 9;
  box-shadow: 0 10px 15px -3px rgb(46 41 51 / 8%), 0 4px 6px -2px rgb(71 63 79 / 16%);
}

.menu.nav {
  background: grey;
  max-width: 300px;
  z-index: 8;
} 

.menu.nav.show-menu {
  transform: translateX(0);
  transition: transform ease-in-out 0.2s;
}

.menu.nav.hide-menu {
  transform: translateX(130px);
  transition: transform ease-in-out 0.2s;
}

.container {
  width: 41px;
  height: 300000px;
  background-image: url(menu.png);
  background-repeat: repeat;
  background-size: auto;
  animation: 9000s linear infinite marquee;
}

/* Animación */
@keyframes marquee {
  0% { transform: translateY(-150000px); }
  100% { transform: translateY(100%); }
}
